
import { ApiResponse, handleApiCall, setSession } from './api';
import { SHA512 } from 'crypto-js';
import Timezone from 'timezone-enum';

export async function signIn(user: string, rawPassword: string, browser: string):
    Promise<ApiResponse<{ token: string }>> {

    const password = SHA512(rawPassword).toString();

    const response = await handleApiCall<{ token: string }>('post', 'v2/public/auth', {
        body: {
            username: user,
            password,
            browser
        }
    })

    if (response.success) {
        const { token } = response.data;
        setSession(token)
    }

    return response;
}
export async function confirmPwordReset(token: string, pword?: string): Promise<ApiResponse<void>> {

    if (pword) {
        return await handleApiCall<void>('post', 'v2/public/confirm', {
            body: {
                token,
                new_password: SHA512(pword).toString()
            }
        });
    }
    else {
        return await handleApiCall<void>('post', 'v2/public/confirm', { body: { token } });
    }
}

export async function requestPwordReset(user: string): Promise<ApiResponse<void>> {

    return await handleApiCall<void>('post', 'v2/public/reset', {
        body: {
            username: user,
        }
    });
}

export async function signUp(name: string, first_name: string,
    last_name: string, username: string, lang: string, captcha?: string): Promise<ApiResponse<void>> {

    const regionCode = navigator.language.split('-')[1];

    return await handleApiCall<void>('post', 'v2/public/register', {
        body: {
            username,
            name,
            first_name,
            last_name,
            lang,
            country: regionCode,
            timezone: Timezone[Intl.DateTimeFormat().resolvedOptions().timeZone],
            captcha,
        }
    })
}

export async function getPublicSettings(): Promise<ApiResponse<{ emailing_enabled: boolean, registration_enabled: boolean }>> {

    return await handleApiCall('get', 'v2/public/settings');
}

export async function verify(token: string, pword?: string): Promise<ApiResponse<void>> {

    if (pword) {
        return await handleApiCall<void>('post', 'v2/public/verify', {
            body: {
                token,
                new_password: SHA512(pword).toString()
            }
        });
    }
    else {
        return await handleApiCall<void>('post', 'v2/public/verify', { body: { token } });
    }
}
