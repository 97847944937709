import * as Yup from 'yup';
import { dbLengthDefinitions, settingsLengthDefinitions } from 'src/interfaces/constants';

export const emailSchema = Yup.string().email('yupEmailInvalid');
export const emailSchemaNullable = Yup.string().email('yupEmailInvalid').nullable();

export const thirdParty = Yup.boolean();

export const passwordNoRestrictions = Yup.string()
  .min(6, 'yupPasswordMin')
  .required('yupPasswordRequired');

export const repeatedPassword = Yup.string()
  .oneOf([Yup.ref('newPword'), null], 'yupPasswordMatch')
  .required('yupPasswordConfirm');

export const username = Yup.string()
  .matches(
    new RegExp(`^(?:[a-zA-Z0-9_.@-]{${dbLengthDefinitions.one},${dbLengthDefinitions.username}}|[^@]+@[^@]+\\.[a-zA-Z]{${dbLengthDefinitions.two},${dbLengthDefinitions.username}})$`),
    'yupUsernameRegex'
  )
  .required('yupUsernameRequired');


export const usernameNullable = Yup.string()
  .matches(
    new RegExp(`^(?:[a-zA-Z0-9_.@-]{${dbLengthDefinitions.one},${dbLengthDefinitions.username}}|[^@]+@[^@]+\\.[a-zA-Z]{${dbLengthDefinitions.two},${dbLengthDefinitions.username}})$`),
    'yupUsernameRegex'
  ).nullable();

export const conditionalUsername = Yup.string()
  .when('via_email', {
    is: (via_email) => via_email === true,
    then: emailSchema,
    otherwise: username,
  });

export const first_name = Yup.string()
  .matches(
    new RegExp(`^.{${dbLengthDefinitions.one},${dbLengthDefinitions.firstName}}$`),
    'yupFirstnameRegex'
  )
  .required('yupFirstnameRequired');

export const last_name = Yup.string()
  .matches(
    new RegExp(`^.{${dbLengthDefinitions.one},${dbLengthDefinitions.lastName}}$`),
    'yupLastnameRegex'
  )
  .required('yupLastnameRequired');

export const teamName = Yup.string()
  .matches(new RegExp('^.'))
  .min(dbLengthDefinitions.one, 'yupTeamMin')
  .max(dbLengthDefinitions.teamName, 'yupTeamMax')
  .required('yupTeamRequired');

export const associationName = Yup.string()
  .min(dbLengthDefinitions.one, 'yupAssoMin')
  .max(dbLengthDefinitions.teamName, 'yupAssoMax')
  .required('yupAssoRequired');

export const notes = Yup.string().max(dbLengthDefinitions.notes, 'yupNotesMax');

export const city = Yup.string().max(dbLengthDefinitions.city, 'yupCityMax');

export const institution = Yup.string().max(dbLengthDefinitions.institution, 'yupInstitutionMax');

export const licenseCount = Yup.number()
  .min(dbLengthDefinitions.one, 'yupLicenseCreationMin')
  .max(parseInt('9'.repeat(dbLengthDefinitions.licenseCount), 10), 'yupLicenseCreationMax')
  .required('yupLicenseCreationRequired');

export const jwtToken = Yup.string()
  .min(settingsLengthDefinitions.min, 'yupLicenseActivationTokenMin')
  .max(dbLengthDefinitions.jwtToken, 'yupLicenseActivationTokenMax')
  .required('yupLicenseActivationTokenRequired')

export const appVersionSchema = Yup.string()
  .matches(
    new RegExp(`^(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.(0|[1-9]\\d*)$`),
    //new RegExp(`^(0|[1-9]\\d{0,${Math.floor(dbLengthDefinitions.appVersion) / 3}})\\.(0|[1-9]\\d{0,${Math.floor(dbLengthDefinitions.appVersion) / 3}})\\.(0|[1-9]\\d{0,${Math.floor(dbLengthDefinitions.appVersion) / 3}})$`),
    'yupAppVersionRegex')
  .required('yupAppVersionRequired');

export const url = Yup.string()
  .matches(
    new RegExp(`^(?!.*\\.$)([^\\s/$.?#][^\\s]{${dbLengthDefinitions.zero},${dbLengthDefinitions.url}})?$`),
    'yupURLRegex')
  .required('yupURLRequired');

export const ttlSchema = Yup.number()
  .min(settingsLengthDefinitions.min, 'yupTTLMin')
  .max(dbLengthDefinitions.ttl, 'yupTTLMax')
  .required('yupTTLRequired');

export const ttlOrZeroSchema = Yup.number()
  .min(dbLengthDefinitions.zero, 'yupTTLMin')
  .max(dbLengthDefinitions.ttl, 'yupTTLMax')
  .required('yupTTLRequired');

export const timeoutSchema = Yup.number()
  .min(settingsLengthDefinitions.min, 'yupTimeoutMin')
  .max(settingsLengthDefinitions.rateLimit, 'yupTimeoutMax')
  .required('yupTimeoutRequired');

export const failedAttemptsSchema = Yup.number()
  .min(settingsLengthDefinitions.min, 'yupFailedAttemptsMin')
  .max(parseInt('9'.repeat(dbLengthDefinitions.failedAttempts), 10), 'yupFailedAttemptsMax')
  .required('yupFailedAttemptsRequired');

export const otpLengthSchema = Yup.number()
  .min(dbLengthDefinitions.one, 'yupOTPLengthMin')
  .max(dbLengthDefinitions.otpMaxLength, 'yupOTPLengthMax')
  .required('yupOTPLengthRequired');

export const repeatedPwordSchema = Yup.number()
  .min(dbLengthDefinitions.one, 'yupRepeatedPwordMin')
  .max(dbLengthDefinitions.otpMaxLength, 'yupRepeatedPwordMax')
  .required('yupRepeatedPwordRequired');

export const portSchema = Yup.number()
  .min(dbLengthDefinitions.one, 'yupPortMin')
  .max(65535, 'yupPortMax')
  .required('yupPortRequired');

export const prefixSchema = Yup.string()
  .matches(
    new RegExp(`^[0-9a-zA-Z_-]{${settingsLengthDefinitions.min},${settingsLengthDefinitions.prefix}}$`),
    'yupPrefixRegex')
  .required('yupPrefixRequired');

export const slugSchema = Yup.string().matches(
  new RegExp('^[a-zA-Z0-9_]+$')).required();


export const yup = {
  en: {
    yupPortMin: 'Port Number too low',
    yupPortMax: 'Port Number too high',
    yupPortRequired: 'Port Number is required',
    yupEmailInvalid: 'Invalid Email address',
    yupPasswordInvalidCharacters: 'Password must contain letters in upper and lower case, numbers, and at least one of the following special characters: !, @, #, $, %, ^, &, *, (, ), [, ], {, }, :, ;',
    yupPasswordMin: 'Password must be at least 9 character length',
    yupPasswordMax: 'Password must be less than 20 characters long',
    yupPasswordRequired: 'Password is required!',
    yupPasswordMatch: 'Passwords must match',
    yupPasswordConfirm: 'Password confirmation is required!',
    yupUsernameRegex: 'Invalid username',
    yupUsernameRequired: 'A username is required!',
    yupFirstnameRegex: 'Invalid first name',
    yupFirstnameRequired: 'First Name is required!',
    yupLastnameRegex: 'Invalid last name',
    yupLastnameRequired: 'Last Name is required!',
    yupTeamMin: 'Invalid team name',
    yupTeamMax: 'Team name too long',
    yupTeamRequired: 'Team name is required!',
    yupAssoMin: 'Invalid association name',
    yupAssoMax: 'Team association too long',
    yupAssoRequired: 'Team association is required!',
    yupNotesMax: 'Notes field too long',
    yupCityMax: 'City field too long',
    yupInstitutionMax: 'Institution field too long',
    yupLicenseCreationMin: 'Number of licenses too small',
    yupLicenseCreationMax: 'Exceeded maximum number of licenses allowed by the system',
    yupLicenseCreationRequired: 'The number of licenses is required',
    yupLicenseActivationTokenMin: 'Activation token too short',
    yupLicenseActivationTokenMax: 'Activation token too big',
    yupLicenseActivationTokenRequired: 'Activation token required',
    yupAppVersionRegex: 'Invalid app version (must be X.Y.Z)',
    yupAppVersionRequired: 'App version required',
    yupTTLMin: 'TTL too small',
    yupTTLMax: 'Exceeded maximum allowed TTL',
    yupTTLRequired: 'A value for TTL is required',
    yupRateLimitMin: 'Rate limit too small',
    yupRateLimitMax: 'Exceeded maximum allowed rate limit',
    yupRateLimitRequired: 'A value for rate limit is required',
    yupTimeoutMin: 'Timeout too small',
    yupTimeoutMax: 'Exceeded maximum allowed timeout',
    yupTimeoutRequired: 'A value for timeout is required',
    yupURLRegex: 'Invalid URL',
    yupURLRequired: 'URL required',
    yupFailedAttemptsMin: 'Value for maximum failed signin attempts too low',
    yupFailedAttemptsMax: 'Value for maximum failed signin attempts too high',
    yupFailedAttemptsRequired: 'Value for maximum failed signin attempts required',
    yupOTPLengthMin: 'Value for OTP length too low',
    yupOTPLengthMax: 'Value for OTP length too high',
    yupOTPLengthRequired: 'Value for OTP length required',
    yupRepeatedPwordMin: 'Value for repeated password history too low',
    yupRepeatedPwordMax: 'Value for repeated password history too high',
    yupRepeatedPwordRequired: 'Value for repeated password history required',
    yupPrefixRegex: 'Invalid prefix',
    yupPrefixRequired: 'Prefix required',
    yupCiphersRegex: 'Invalid ciphers',
    yupCiphersRequired: 'Ciphers required',
  },
  pt: {
    yupPortMin: 'Número de porta muito baixo',
    yupPortMax: 'Número de porta muito alto',
    yupPortRequired: 'Número de porta necessário',
    yupEmailInvalid: 'Endereço de e-mail inválido',
    yupPasswordInvalidCharacters: 'A senha deve conter letras maiúsculas e minúsculas, números e pelo menos um dos seguintes caracteres especiais: !, @, #, $, %, ^, &, *, (, ), [, ], {, }, :, ;',
    yupPasswordMin: 'A senha deve ter no mínimo 9 caracteres',
    yupPasswordMax: 'A senha deve ter menos de 20 caracteres',
    yupPasswordRequired: 'A senha é obrigatória!',
    yupPasswordMatch: 'As senhas devem coincidir',
    yupPasswordConfirm: 'A confirmação da senha é obrigatória!',
    yupUsernameRegex: 'Nome de usuário inválido',
    yupUsernameRequired: 'Um nome de usuário é obrigatório!',
    yupFirstnameRegex: 'Nome inválido',
    yupFirstnameRequired: 'O Primeiro Nome é obrigatório!',
    yupLastnameRegex: 'Sobrenome inválido',
    yupLastnameRequired: 'O Sobrenome é obrigatório!',
    yupTeamMin: 'Nome da equipe inválido',
    yupTeamMax: 'Nome da equipe muito longo',
    yupTeamRequired: 'O nome da equipe é obrigatório!',
    yupAssoMin: 'Nome da associação inválido',
    yupAssoMax: 'Nome da associação muito longo',
    yupAssoRequired: 'O nome da associação é obrigatório!',
    yupNotesMax: 'Campo observações muito longo',
    yupCityMax: 'Campo cidade muito longo',
    yupInstitutionMax: 'Campo instituição muito longo',
    yupLicenseCreationMin: 'Número de licenças muito baixo',
    yupLicenseCreationMax: 'Exceeded maximum number of licenses allowed by the system',
    yupLicenseCreationRequired: 'O número de licenças é necessário',
    yupLicenseActivationTokenMin: 'Token de ativação muito curto',
    yupLicenseActivationTokenMax: 'Token de ativação muito longo',
    yupLicenseActivationTokenRequired: 'Token de ativação obrigatório',
    yupAppVersionRegex: 'Versão de aplicação inválida (deve ser X.Y.Z)',
    yupAppVersionRequired: 'Versão de aplicação obrigatória',
    yupTTLMin: 'TTL muito curto',
    yupTTLMax: 'TTL muito longo',
    yupTTLRequired: 'Um valor para TTL é necessário',
    yupRateLimitMin: 'Limite de acessos muito baixo',
    yupRateLimitMax: 'Limite de acessos muito alto',
    yupRateLimitRequired: 'Um valor para o limite de acessos é obrigatório',
    yupTimeoutMin: 'Timeout muito baixo',
    yupTimeoutMax: 'Exceeded muito alto',
    yupTimeoutRequired: 'Um valor para timeout é necessário',
    yupURLRegex: 'URL inválida',
    yupURLRequired: 'URL exigida',
    yupFailedAttemptsMin: 'Valor para número máximo de tentativas de acesso muito baixo',
    yupFailedAttemptsMax: 'Valor para número máximo de tentativas de acesso muito alto',
    yupFailedAttemptsRequired: 'Valor para número máximo de tentativas de acesso exigido',
    yupOTPLengthMin: 'Tamanho do OTP muito baixo',
    yupOTPLengthMax: 'Tamanho do OTP muito alto',
    yupOTPLengthRequired: 'Tamanho do OTP exigido',
    yupRepeatedPwordMin: 'Valor para o histórico de senhas repetidas muito baixo',
    yupRepeatedPwordMax: 'Valor para o histórico de senhas repetidas muito alto',
    yupRepeatedPwordRequired: 'Valor para o histórico de senhas repetidas exigido',
    yupPrefixRegex: 'Prefixo inválido',
    yupPrefixRequired: 'Prefixo exigido',
    yupCiphersRegex: 'Cifras inválidas',
    yupCiphersRequired: 'Cifras exigidas',
  },
  es: {
    yupPortMin: 'Número de puerto demasiado bajo',
    yupPortMax: 'Número de puerto demasiado alto',
    yupPortRequired: 'Se requiere el número de puerto',
    yupEmailInvalid: 'Dirección de correo electrónico no válida',
    yupPasswordInvalidCharacters: 'La contraseña debe contener letras mayúsculas y minúsculas, números y al menos uno de los siguientes caracteres especiales: !, @, #, $, %, ^, &, *, (, ), [, ], {, }, :, ;',
    yupPasswordMin: 'La contraseña debe tener al menos 9 caracteres',
    yupPasswordMax: 'La contraseña debe tener menos de 20 caracteres',
    yupPasswordRequired: '¡Se requiere una contraseña!',
    yupPasswordMatch: 'Las contraseñas deben coincidir',
    yupPasswordConfirm: '¡Se requiere la confirmación de la contraseña!',
    yupUsernameRegex: 'Nombre de usuario no válido',
    yupUsernameRequired: '¡Se requiere un nombre de usuario!',
    yupFirstnameRegex: 'Nombre no válido',
    yupFirstnameRequired: '¡Se requiere el nombre!',
    yupLastnameRegex: 'Apellido no válido',
    yupLastnameRequired: '¡Se requiere el apellido!',
    yupTeamMin: 'Nombre del equipo no válido',
    yupTeamMax: 'Nombre del equipo demasiado largo',
    yupTeamRequired: '¡Se requiere el nombre del equipo!',
    yupAssoMin: 'Nombre de la asociación no válido',
    yupAssoMax: 'Nombre de la asociación demasiado largo',
    yupAssoRequired: '¡Se requiere el nombre de la asociación!',
    yupNotesMax: 'El campo de notas es demasiado largo',
    yupCityMax: 'El campo de ciudad es demasiado largo',
    yupInstitutionMax: 'El campo de institución es demasiado largo',
    yupLicenseCreationMin: 'Número de licencias demasiado bajo',
    yupLicenseCreationMax: 'Se excedió el número máximo de licencias permitidas por el sistema',
    yupLicenseCreationRequired: 'Se requiere el número de licencias',
    yupLicenseActivationTokenMin: 'El token de activación es demasiado corto',
    yupLicenseActivationTokenMax: 'El token de activación es demasiado largo',
    yupLicenseActivationTokenRequired: 'El token de activación es obligatorio',
    yupAppVersionRegex: 'Versión de la aplicación no válida (debe ser X.Y.Z)',
    yupAppVersionRequired: 'Se requiere la versión de la aplicación',
    yupTTLMin: 'TTL demasiado corto',
    yupTTLMax: 'TTL excede el máximo permitido',
    yupTTLRequired: 'Se requiere un valor para TTL',
    yupRateLimitMin: 'El límite de tasa es demasiado bajo',
    yupRateLimitMax: 'El límite de tasa excede el máximo permitido',
    yupRateLimitRequired: 'Se requiere un valor para el límite de tasa',
    yupTimeoutMin: 'El tiempo de espera es demasiado corto',
    yupTimeoutMax: 'El tiempo de espera excede el máximo permitido',
    yupTimeoutRequired: 'Se requiere un valor para el tiempo de espera',
    yupURLRegex: 'URL no válida',
    yupURLRequired: 'Se requiere la URL',
    yupFailedAttemptsMin: 'Valor para el máximo de intentos fallidos de inicio de sesión demasiado bajo',
    yupFailedAttemptsMax: 'Valor para el máximo de intentos fallidos de inicio de sesión demasiado alto',
    yupFailedAttemptsRequired: 'Se requiere un valor para el máximo de intentos fallidos de inicio de sesión',
    yupOTPLengthMin: 'La longitud del OTP es demasiado corta',
    yupOTPLengthMax: 'La longitud del OTP es demasiado larga',
    yupOTPLengthRequired: 'Se requiere la longitud del OTP',
    yupRepeatedPwordMin: 'El valor para el historial de contraseñas repetidas es demasiado bajo',
    yupRepeatedPwordMax: 'El valor para el historial de contraseñas repetidas es demasiado alto',
    yupRepeatedPwordRequired: 'Se requiere un valor para el historial de contraseñas repetidas',
    yupPrefixRegex: 'Prefijo no válido',
    yupPrefixRequired: 'Se requiere el prefijo',
    yupCiphersRegex: 'Cifras no válidas',
    yupCiphersRequired: 'Se requieren cifras',
  }
}